import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import useMediaQuery from '../../hooks/useMediaQuery'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination, Mousewheel } from 'swiper'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

const MainSlider = ({ slice }) => {
  const isDesktop = useMediaQuery('(min-width:599px)')

  const desktopImages = slice.items.map((img, index) => {
    return (
      <SwiperSlide key={index}>
        <GatsbyImage
          image={img.desktop_image.gatsbyImageData}
          alt="img"
          className="hero-slider__image"
        />
      </SwiperSlide>
    )
  })
  const mobileImages = slice.items.map((img, index) => {
    return (
      <SwiperSlide key={index}>
        <GatsbyImage
          image={img.mobile_image.gatsbyImageData}
          alt="img"
          className="hero-slider__image"
        />
      </SwiperSlide>
    )
  })
  return (
    <section className="homepage_section">
      <div className="swiper-container">
        <Swiper
          slidesPerView="auto"
          navigation={true}
          pagination={{
            type: 'fraction',
          }}
          mousewheel={true}
          modules={[Navigation, Pagination, Mousewheel]}
          className="mySwiper"
        >
          {isDesktop ? desktopImages : mobileImages}
        </Swiper>
      </div>
      <a className="primary_button" href="/works">
        MY WORK
      </a>
    </section>
  )
}

export default MainSlider
