import React from 'react'
import MainSlider from './MainSlider'
import CategoryGallery from './CategoryGallery'
import AboutSection from './AboutSection'

const SliceZone = ({ slices, settings }) => {
  const sliceComponents = {
    main_slider: MainSlider,
    image_gallery: CategoryGallery,
    about_section: AboutSection,
  }

  return slices.map((slice, index) => {
    const SliceComponent = sliceComponents[slice.slice_type]
    const key = slice.id + index

    if (SliceComponent) {
      return <SliceComponent slice={slice} settings={settings} key={key} />
    }

    return null
  })
}

export default SliceZone
