import React from 'react'
import { useState } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import * as styles from './CategoryGallery.module.css'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Keyboard, Navigation, Pagination } from 'swiper'
import { CloseX } from '../../helpers/icons'
import 'swiper/css'
import 'swiper/css/effect-fade'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

const CategoryGallery = ({ slice }) => {
  const [lightboxDisplay, setLightBoxDisplay] = useState(false)
  const [imageToShow, setImageToShow] = useState({})
  const [currentIndex, setCurrentIndex] = useState(null)

  const showImage = (image, idx) => {
    setCurrentIndex(idx)
    setImageToShow(image)
    setLightBoxDisplay(true)
    console.log(image, idx)
  }

  const closeLightBox = () => {
    setLightBoxDisplay(false)
  }

  const lightboxImages = slice.items.map((item, index) => {
    return (
      <SwiperSlide key={`img ${index}`}>
        <GatsbyImage
          className="img-responsive"
          image={item.image.gatsbyImageData} // imageToShow -> shows the image that clicked but only this one on each slide
          alt="img"
        />
      </SwiperSlide>
    )
  })

  const gallery = slice.items.map((item, index) => {
    return (
      <button
        className={styles.open_lightbox}
        onClick={() => showImage(item.image.gatsbyImageData, index)}
      >
        <GatsbyImage
          className={styles.lightbox_image}
          key={index}
          image={item.image.gatsbyImageData}
          alt="img"
        />
      </button>
    )
  })
  return (
    <>
      {lightboxDisplay ? (
        <div id={styles.lightbox}>
          <button className={styles.close} onClick={closeLightBox}>
            <CloseX />
          </button>
          <div className={styles.lightbox_child}>
            <Swiper
              spaceBetween={30}
              navigation={true}
              initialSlide={currentIndex}
              keyboard={{
                enabled: true,
              }}
              pagination={{
                clickable: true,
              }}
              modules={[Keyboard, Navigation, Pagination]}
              className="lightbox_swiper"
            >
              {lightboxImages}
            </Swiper>
          </div>
        </div>
      ) : (
        ''
      )}
      <div className="subproject_wrapper">
        <h1 className="category_subtitle container">
          {slice.primary.gallery_title.text}
        </h1>
        <div className="grid_layout">{gallery}</div>
      </div>
    </>
  )
}

export default CategoryGallery
